
dl.anim_in_table
  opacity: 0
  transition: 0.5s ease all
  &.shown
    opacity: 1


div#AboutMe
  a
    color: white

  p.linksRow
    span, a
      text-decoration: underline
      font-weight: bold
      cursor: pointer