dl.anim_in_table {
  opacity: 0;
  transition: 0.5s ease all;
}
dl.anim_in_table.shown {
  opacity: 1;
}

div#AboutMe a {
  color: white;
}
div#AboutMe p.linksRow span, div#AboutMe p.linksRow a {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

