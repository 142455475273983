div.canvasContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: None;
  z-index: -10;
}

